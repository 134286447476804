import { defineStore } from 'pinia'
import axios from 'axios'

export const useCommercialStore = defineStore('commercialStore', {
    state: () => ({

        // Объект
        item: {},

        // Похожие объекты
        similar_offers: {
            items: [],
            coutn: 0
        },

        // Здание( Родительский объект )
        build_item: {},

        // Объекты
        items: [],

        // Координаты
        coordinates: [],

        view: 'list',

        // Объекты родительского объекта
        build_offers: {
            items: [],
            coutn: 0
        },

        // кол-во найдено
        count: 0,

        filter: {
            search: "",
            sort: { 
                id: 'default', 
                label: 'По умолчанию' 
            },
            offer: null,
            offertype: null,
            subtype: null,
            type: null,
            price_ot: null,
            price_do: null,
            mode: null,
            price_from: {
                id: 'kvm',
                label: 'кв.м.'
            },
            area_ot: null,
            area_do: null,
            metros: null,
            raions: null,
            city_id: 1,
            page: 1,
        },

        sorts: [
            { id: 'default', label: 'По умолчанию' },
            { id: 'price_asc', label: 'Сначала дешевле' },
            { id: 'price_desc', label: 'Сначала дороже' },
            { id: 'area_asc', label: 'Меньше площадь' },
            { id: 'area_desc', label: 'Больше площадь' },
        ],

        // Когда находишься на сео странице при изменении фильтра адрес
        // переформировывается в /catalog?...
        seo_mode: false,

        // Прячет/Показывает список при переформировании результатов от зависаний
        showList: true

    }),
    actions: {
        async getList(){
            if( this.items.length == 0 ){
                const { data } = await axios.get('/api/realty/query')
                
                this.items = data
            }
            
            return this.items
        },
        async getQuery( params = {}, return_data = false, reset = true ){
            // console.log('getQuery', return_data, reset);
            const { data } = await axios.post('/api/realty/query', params )
            if( return_data ){
                if( reset ){
                    this.count = data.response.total
                    this.items = data.response.items
                    this.coordinates = data.response.ll
                }else{
                    this.items = this.items.concat( data.response.items )
                    // console.log(' data query', this.items);
                }
            } 
            if( !return_data ){
                return data.response
            }
        },
        resetFilter(){
            this.filter.search = ""
            this.filter.mode = null
            this.filter.offertype = null
            this.filter.type = null
            this.filter.price_ot = null
            this.filter.price_do = null
            this.filter.area_ot = null
            this.filter.area_do = null
            this.filter.metros = []
            this.filter.raions = []
        },
        async parseParams( query = false ){
            // console.log('parseParams query', query);
            const metrosStore = useMetrosStore()
            const raionsStore = useRaionsStore()
            const app_config = useAppConfig()
            if( !query ){
                const route = useRoute()
                query = route.query
            }
            this.resetFilter()
            // console.log('query length', Object.keys( query ).length );
            if( Object.keys( query ).length > 0 ){
                // console.log('query', query);
                if ( query.text && query.text.length > 0) this.filter.search = query.text;
                if ( query.type && query.type.length > 0) this.filter.type = query.type;
                if ( query.offertype && query.offertype.length > 0 ) this.filter.offer = query.offertype;
                if ( query.subtype && query.subtype.slug ) this.filter.subtype = query.subtype.slug;
                if ( query.mode && query.mode.length > 0 ) this.filter.mode = query.mode;
                if ( query.price_ot && parseInt(query.price_ot) > 0) this.filter.price_ot = query.price_ot;
                if ( query.price_do && parseInt(query.price_do) > 0) this.filter.price_do = query.price_do;
                if ( query.area_ot && parseInt(query.area_ot) > 0) this.filter.area_ot = query.area_ot;
                if ( query.area_do && parseInt(query.area_do) > 0) this.filter.area_do = query.area_do;
                // this.filter.metros = []
                if (query.metros && query.metros.split(',').length > 0 ){
                    query.metros.split(',').map( metro_id => { 
                        let metro_instance = metrosStore.items.find( m => m.id == metro_id && m.city_id == this.filter.city_id )
                        this.filter.metros.push( metro_instance )
                    });
                } 
                // this.filter.raions = []
                if (query.raions && query.raions.split(',').length > 0 ){
                    query.raions.split(',').map( raion_id => { 
                        let raion_instance = raionsStore.items.find( m => m.id == raion_id && m.city_id == this.filter.city_id )
                        this.filter.raions.push( raion_instance )
                    });
                }
                if ( query.sort !== undefined ){
                    let sort_instance = this.sorts.find( s => s.id == query.sort )
                    this.filter.sort = sort_instance
                } 
                // if ( query.price_from == undefined ){
                //     let offer = 'arenda'
                //     if( query.offertype == 'продажа' ){
                //         offer = 'prodaja'
                //     }
                //     let offer_data = app_config[ offer ]
                //     let price_from_instance = null
                //     offer_data.prices_units.map( item => {
                //         if( item.id == query.price_from ){
                //             price_from_instance = item.id
                //         }
                //     })
                //     this.filter.price_from = price_from_instance
                // }
                
                // else{
                //     this.filter.price_from = query.price_from
                // }
            } 
        },
        async applyFilter( reset = true ){
            const metrosStore = useMetrosStore()
            const raionsStore = useRaionsStore()
            // console.log('applyFilter', reset);
            const router = useRouter();
            let query = {
                offertype: 'arenda,prodaja',
                page: 1
            };
            if( reset == true ){
                this.filter.page = 1
            } 
            if (this.filter.sort && this.filter.sort.id !== 'default' ) query.sort = this.filter.sort.id
            if (this.filter.page && this.filter.page > 1) query.page = this.filter.page;
            if (this.filter.city_id && this.filter.city_id > 0) query.city_id = this.filter.city_id;
            if (this.filter.search && this.filter.search.length > 0) query.text = this.filter.search;
            if (this.filter.offer && this.filter.offer.length > 0) query.offertype = this.filter.offer;
            if (this.filter.type && this.filter.type.length > 0) query.type = this.filter.type;
            if (this.filter.subtype && this.filter.subtype.length > 0) query.subtype = this.filter.subtype;
            if (this.filter.mode && this.filter.mode.length > 0) query.mode = this.filter.mode;
            if (parseInt(this.filter.price_ot) > 0) query.price_ot = this.filter.price_ot;
            if (parseInt(this.filter.price_do) > 0) query.price_do = this.filter.price_do;
            if ( this.filter.price_from ){
                if( typeof this.filter.price_from == 'string'  ) query.price_from = this.filter.price_from;
                if( typeof this.filter.price_from == 'object' ) query.price_from = this.filter.price_from.id;
            } 
            if (parseInt(this.filter.area_ot) > 0) query.area_ot = this.filter.area_ot;
            if (parseInt(this.filter.area_do) > 0) query.area_do = this.filter.area_do;
            if (this.filter.metros && this.filter.metros.length > 0) query.metros = this.filter.metros.map((e) => e.id).join(",");
            if (this.filter.raions && this.filter.raions.length > 0) query.raions = this.filter.raions.map((e) => e.id).join(",");
            if( !this.seo_mode ){
                router.push({
                    path: "/catalog",
                    query: Object.assign( {}, query, {
                        // page: this.filter.page
                    }),
                });
            }
            if( query.raions && query.raions.length > 0 ){
                let _items = []
                query.raions.split(',').map( item_id => { 
                    let instance = raionsStore.items.find( item => item.id == item_id && item.city_id == this.filter.city_id )
                    _items.push( instance.header )
                });
                query.raions = _items.join(',')
            }
            if( query.metros && query.metros.length > 0 ){
                let _items = []
                query.metros.split(',').map( item_id => { 
                    let instance = metrosStore.items.find( item => item.id == item_id && item.city_id == this.filter.city_id )
                    _items.push( instance.header )
                });
                query.metros = _items.join(',')
            }
            await this.getQuery( query, true, reset )
        },
        async paginator(){
            this.filter.page++
            this.applyFilter( false )
        },
        async getItem( id = false ){
            const { data } = await useFetch(`${useRequestURL().origin}/api/realty/offer/${id}`)
            // const { data } = await axios.get(`/api/realty/offer/${id}`)
            // console.log('data', data);
            this.item = data.value.response
            return ( data.status == 'success' ) ? true : false
        },
        async getBuildItem( id = false ){
            const { data } = await useFetch(`${useRequestURL().origin}/api/realty/build/${id}`)
            // const { data } = await axios.get(`/api/realty/build/${id}`)
            // console.log('data', data);
            this.build_item = data.value.response
            return ( data.status == 'success' ) ? true : false
        },
        async getBuildOffers( id = false ){
            const { data } = await useFetch(`${useRequestURL().origin}/api/realty/build/${id}/offers`)
            // const { data } = await axios.get(`/api/realty/build/${id}/offers`)
            const { status, message, response } = data.value
            this.build_offers.items = []
            this.build_offers.count = 0
            if( status == 'success' ){
                this.build_offers.items = response?.items
                this.build_offers.count = parseInt( response?.total )
            }
        },
        async getIntrumId( id = false ){
            const { data } = await useFetch(`${useRequestURL().origin}/api/realty/bci/${id}`)
            const { status, message, response } = data.value
            return ( status == 'success') ? response : message
        },
        async getOld( id = false ){
            const { data } = await useFetch(`${useRequestURL().origin}/api/realty/bc/${id}`)
            const { status, message, response } = data.value
            return ( status == 'success') ? response : message
        },
        async getOldSpace( id = false ){
            const { data } = await useFetch(`${useRequestURL().origin}/api/realty/space/${id}`)
            const { status, message, response } = data.value
            return ( status == 'success') ? response : message
        },
    }
})

// 3672 > части
// 3292 > план
// 510 > общ

/// Карточке обьекта - [3672, 510] / [3672, 510] / [3292, 3672, 510] / [510, 3292, 3672]
/// Странице обьекта - 



// 3769 > 625