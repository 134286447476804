import { defineStore } from 'pinia'

export const useMetrosStore = defineStore('metrosStore', {
    state: () => ({
        items: [],
    }),
    actions: {
        async getList(){
            if( !this.items ) this.items = []
            if( this.items.length == 0 ){
                const { data } = await useFetch(`${useRequestURL().origin}/api/metros`)
                this.items = data.value
            }
            return this.items
        }
    }
})