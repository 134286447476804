import { defineStore } from 'pinia'

export const useRaionsStore = defineStore('raionsStore', {
    state: () => ({
        items: [],
    }),
    actions: {
        async getList(){
            if( !this.items ) this.items = []
            if( this.items.length < 1 ){
                const { data } = await useFetch(`${useRequestURL().origin}/api/raions`)
                this.items = data.value
            }
            return this.items
        }
    }
})